<template>
  <component @show-enlarged="showEnlarged" :is="`${node.id}-widget`" :widget="node.data" />
</template>

<script>
  import LayerWidget from '@/components/templates/designer/widgets/LayerWidget.vue';
  import TextWidget from '@/components/templates/designer/widgets/TextWidget.vue';
  import ClockWidget from '@/components/templates/designer/widgets/ClockWidget.vue';
  import ButtonWidget from '@/components/templates/designer/widgets/ButtonWidget.vue';
  import ImageWidget from '@/components/templates/designer/widgets/ImageWidget.vue';
  import AppWidget from '@/components/templates/designer/widgets/AppWidget.vue';
  import VideoWidget from '@/components/templates/designer/widgets/VideoWidget.vue';
  import PdfWidget from '@/components/templates/designer/widgets/PdfWidget.vue';
  import RichtextWidget from './RichTextWidget.vue';
  import AudioWidget from '@/components/templates/designer/widgets/AudioWidget.vue';

  export default {
    name: 'WidgetNode',

    props: {
      node: {
        type: Object,
        default: () => {},
      },
    },

    methods: {
      showEnlarged(w) {
        this.$emit('show-enlarged', w);
      },
    },

    components: {
      AudioWidget,
      ImageWidget,
      LayerWidget,
      TextWidget,
      ClockWidget,
      ButtonWidget,
      AppWidget,
      VideoWidget,
      PdfWidget,
      RichtextWidget,
    },
  };
</script>

<style scoped></style>
