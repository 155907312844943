import { ButtonContentType } from '@/types/widgets/buttonWidget/buttonWidget';

export const WIDGET_TYPES = {
  LAYER: 'layer',
  PLAYLIST: 'playlist',
  BUTTON: 'button',
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  TEXT: 'text',
  RICH_TEXT: 'richtext',
  PDF: 'pdf',
  APP: 'app',
  CLOCK: 'clock',
  LAYOUT_ITEM: 'playlistitem',
  TEMPLATE: 'template',
  TILE: 'local-template',
};

export const WIDGET_TYPE_TO_ROLE_ACCESS_TYPES_MAP = {
  playlist: 'playlist',
  playlistitem: 'playlistitem',
  template: 'templates',
};

export const APP_TYPES = {
  WEATHER: 'weather',
  TRAFIKLAB: 'trafiklab',
  RSS: 'rss',
  TABLE: 'table',
};

export const TEXT_WEIGHT_MODE = {
  medium: 'normal',
  thin: 'lighter',
  bold: 'bold',
};

export const LAYOUT_ASPECT_RATIOS = {
  SIXTEEN_TEN: '16:10',
  SIXTEEN_NINE: '16:9',
  FIVE_FOUR: '5:4',
  FOUR_THREE: '4:3',
  TWENTY_ONE: '21:9',
};

export const LAYOUT_RESOLUTIONS = {
  defaultValues: {
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_TEN]: 'WUXGA',
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE]: 'FHD',
    [LAYOUT_ASPECT_RATIOS.FIVE_FOUR]: 'SXGA',
    [LAYOUT_ASPECT_RATIOS.FOUR_THREE]: 'XGA',
    [LAYOUT_ASPECT_RATIOS.TWENTY_ONE]: 'UWFHD',
  },
  resolutions: {
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_TEN]: {
      'WXGA-16': {
        name: 'WXGA-16',
        widthHeightRatio: 16 / 10,
        ratio: '16:10',
        width: 1280,
        height: 800,
        scaleFactor: 800 / 1280,
        layout: {
          horizontal: { width: 800, height: 500 },
          vertical: { width: 500, height: 800 },
        },
      },
      'WXGA+': {
        name: 'WXGA+',
        widthHeightRatio: 16 / 10,
        ratio: '16:10',
        width: 1440,
        height: 900,
        scaleFactor: 800 / 1440,
        layout: {
          horizontal: { width: 800, height: 500 },
          vertical: { width: 500, height: 800 },
        },
      },
      'WSXGA+': {
        name: 'WSXGA+',
        widthHeightRatio: 16 / 10,
        ratio: '16:10',
        width: 1680,
        height: 1050,
        scaleFactor: 800 / 1680,
        layout: {
          horizontal: { width: 800, height: 500 },
          vertical: { width: 500, height: 800 },
        },
      },
      WUXGA: {
        name: 'WUXGA',
        widthHeightRatio: 16 / 10,
        ratio: '16:10',
        width: 1920,
        height: 1200,
        scaleFactor: 800 / 1920,
        layout: {
          horizontal: { width: 800, height: 500 },
          vertical: { width: 500, height: 800 },
        },
      },
      WQXGA: {
        name: 'WQXGA',
        widthHeightRatio: 16 / 10,
        ratio: '16:10',
        width: 2560,
        height: 1600,
        scaleFactor: 800 / 2560,
        layout: {
          horizontal: { width: 800, height: 500 },
          vertical: { width: 500, height: 800 },
        },
      },
    },
    [LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE]: {
      WXGA: {
        name: 'WXGA',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 1280,
        height: 720,
        scaleFactor: 800 / 1280,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      HD: {
        name: 'HD',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 1360,
        height: 768,
        scaleFactor: 800 / 1360,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      'HD+': {
        name: 'HD+',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 1600,
        height: 900,
        scaleFactor: 800 / 1600,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      '4K UHD': {
        name: '4K UHD',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 3840,
        height: 2160,
        scaleFactor: 800 / 3840,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      FHD: {
        name: 'FHD',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 1920,
        height: 1080,
        scaleFactor: 800 / 1920,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      QWXGA: {
        name: 'QWXGA',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 2048,
        height: 1152,
        scaleFactor: 800 / 2048,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
      QHD: {
        name: 'QHD',
        widthHeightRatio: 16 / 9,
        ratio: '16:9',
        width: 2560,
        height: 1440,
        scaleFactor: 800 / 2560,
        layout: {
          horizontal: { width: 800, height: 450 },
          vertical: { width: 450, height: 800 },
        },
      },
    },
    [LAYOUT_ASPECT_RATIOS.FIVE_FOUR]: {
      SXGA: {
        name: 'SXGA',
        widthHeightRatio: 5 / 4,
        ratio: '5:4',
        width: 1280,
        height: 1024,
        scaleFactor: 800 / 1280,
        layout: {
          horizontal: { width: 800, height: 640 },
          vertical: { width: 640, height: 800 },
        },
      },
    },
    [LAYOUT_ASPECT_RATIOS.FOUR_THREE]: {
      SVGA: {
        name: 'SVGA',
        widthHeightRatio: 4 / 3,
        ratio: '4:3',
        width: 800,
        height: 600,
        scaleFactor: 800 / 800,
        layout: {
          horizontal: { width: 800, height: 600 },
          vertical: { width: 600, height: 800 },
        },
      },
      XGA: {
        name: 'XGA',
        widthHeightRatio: 4 / 3,
        ratio: '4:3',
        width: 1024,
        height: 768,
        scaleFactor: 800 / 1024,
        layout: {
          horizontal: { width: 800, height: 600 },
          vertical: { width: 600, height: 800 },
        },
      },
      QXGA: {
        name: 'QXGA',
        widthHeightRatio: 4 / 3,
        ratio: '4:3',
        width: 2048,
        height: 1536,
        scaleFactor: 800 / 2048,
        layout: {
          horizontal: { width: 800, height: 600 },
          vertical: { width: 600, height: 800 },
        },
      },
    },
    [LAYOUT_ASPECT_RATIOS.TWENTY_ONE]: {
      UWFHD: {
        name: 'UWFHD',
        widthHeightRatio: 21 / 9,
        ratio: '21:9',
        width: 2560,
        height: 1080,
        scaleFactor: 800 / 2560,
        layout: {
          horizontal: { width: 800, height: 337.5 },
          vertical: { width: 337.5, height: 800 },
        },
      },
      UWQHD: {
        name: 'UWQHD',
        widthHeightRatio: 21 / 9,
        ratio: '21:9',
        width: 3440,
        height: 1440,
        scaleFactor: 800 / 3440,
        layout: {
          horizontal: { width: 800, height: 334.88 },
          vertical: { width: 334.88, height: 800 },
        },
      },
    },
  },
};

export const DEFAULT_LAYOUT_ASPECT_RATIO = LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE;
export const DEFAULT_LAYOUT_RESOLUTION =
  LAYOUT_RESOLUTIONS.defaultValues[DEFAULT_LAYOUT_ASPECT_RATIO];

// these are the schema for both UI form, and BE storage of layout widgets
// be careful when you make changes
// FN generateDefaultWidgetData will add wid for filtering, and type

export const IMAGE_MODE = {
  ORIGIN: 'none',
  CONTAIN: 'contain',
  STRETCH: 'fill',
  CROP: 'cover',
};

export const TRANSITION_MODE = {
  FADE_IN: 'fade',
  SLIDE: 'slide',
};

export const WIDGET_CONTENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  NONE: 'none',
  APP: 'app',
  URL: 'url',
  PDF: 'pdf',
};

export const DEFAULT_LAYER_WIDGET = {
  type: WIDGET_TYPES.LAYER,

  name: 'Layer',
  backgroundColor: '#E9EEF5',
  opacity: 100,

  position: {
    horizontal: {
      width: 30,
      height: 50,
      x: 0,
      y: 0,
      zIndex: 0,
    },
    vertical: {
      width: 30,
      height: 50,
      x: 0,
      y: 0,
      zIndex: 0,
    },
  },

  imageMode: IMAGE_MODE.CROP,
};

export const DEFAULT_BUTTON_WIDGET = {
  type: WIDGET_TYPES.BUTTON,

  name: 'Button Standard',

  width: 30,
  height: 18,
  x: 0,
  y: 0,

  // Image body
  imageAsButton: false,
  imageMode: IMAGE_MODE.CROP,
  imageUrl: null,
  thumbnailUrl: null,

  // Normal body
  backgroundColor: '#3D3D3D',
  captionText: 'Button',
  textFont: 'Arial, Helvetica, sans-serif',
  fontWeight: TEXT_WEIGHT_MODE.medium,
  text: 'Click me',
  textColor: '#FFFFFF',
  textSize: 20,
  embossed: false,

  // Border
  displayBorder: true,
  borderRadius: 3,
  borderWidth: 1,
  borderColor: '#3D3D3D',

  // Shadow
  shadowOpacity: 100,
  shadowBlur: 4,
  displayShadow: true,
  shadowColor: '#666666',

  // modal content
  contentType: ButtonContentType.IMAGE,
  contentLink: null,
  contentThumbnailLink: null,
  idleTimeout: 120,
  closeButton: true,
};

// widget mode constant
// please do not change value of existing constants or there will be data corruption!!!
export const ALIGN_MODES = {
  TEXT_MODE: 'text',
  RSS_MODE: 'rss',

  LEFT_ALIGN: 'left',
  CENTER_ALIGN: 'center',
  RIGHT_ALIGN: 'right',
};

export const DEFAULT_TEXT_WIDGET = {
  type: WIDGET_TYPES.TEXT,

  // widget data
  name: 'Text',

  width: 30,
  height: 30,
  x: 0,
  y: 0,

  headerOn: false,

  headerTextColor: '#000000',
  headerFont: 'Arial',
  headerTextAlignment: ALIGN_MODES.LEFT_ALIGN,
  headerTextOpacity: 100,
  headerBackgroundColor: '#FFFFFF',
  headerBackgroundOpacity: 0,
  headerTextSize: 24,
  headerTextSeparation: 0,
  paddingHeader: 10,
  headerText: 'Title',

  textColor: '#000000',
  textFont: 'Arial',
  opacity: 100,
  paddingText: 10,

  backgroundColor: '#FFFFFF',
  backgroundOpacity: 0,

  mode: ALIGN_MODES.TEXT_MODE,

  userText: 'New Text',

  alignment: ALIGN_MODES.LEFT_ALIGN,

  maximalTextSize: false,
  textSize: 18,

  ticker: false,
  tickerDuration: 10, // secs
  tickerReverse: false,

  marqueeMode: false,
  marqueeSpeed: 10,
};

export const DEFAULT_RICH_TEXT_WIDGET = {
  type: WIDGET_TYPES.RICH_TEXT,
  textSize: -1, // deprecate text size by setting it to -1
  name: 'Rich Text',
  userText:
    '<p><span style="color: #000000; font-family: Arial; font-size: 18px; line-height: 1.2; letter-spacing: 0em">New Text</span></p>',
  width: 30,
  height: 30,
  x: 0,
  y: 0,
};

export const DEFAULT_CLOCK_WIDGET = {
  type: WIDGET_TYPES.CLOCK,

  name: 'Clock',
  showDate: true,

  width: 15,
  height: 15,
  x: 0,
  y: 0,

  textColor: '#FFFFFF',
  opacity: 100,
  textSize: 20,

  backgroundColor: '#000000',
  backgroundOpacity: 30,
};

export const DEFAULT_IMAGE_WIDGET = {
  type: WIDGET_TYPES.IMAGE,

  name: 'Image',
  url: '',
  x: 0,
  y: 0,
  width: 30,
  height: 30,
  opacity: 100,

  imageMode: IMAGE_MODE.CROP,
};

export const DEFAULT_VIDEO_WIDGET = {
  type: WIDGET_TYPES.VIDEO,

  name: 'Video',
  x: 0,
  y: 0,
  width: 25,
  height: 25,
  opacity: 100,
};

export const DEFAULT_AUDIO_WIDGET = {
  type: WIDGET_TYPES.AUDIO,
  name: 'Audio',
  x: 0,
  y: 0,
  width: 25,
  height: 25,
  opacity: 100,
  mute: false,
  volume: 1,
};

export const DEFAULT_PDF_WIDGET = {
  type: WIDGET_TYPES.PDF,

  name: 'PDF',
  x: 0,
  y: 0,
  width: 25,
  height: 25,
  opacity: 100,
};

export const DEFAULT_PLAYLIST_WIDGET = {
  type: WIDGET_TYPES.PLAYLIST,

  name: 'Playlist',

  imageMode: IMAGE_MODE.CROP,
  transition_mode: TRANSITION_MODE.FADE_IN,
  mute: false,
};

export const DEFAULT_APP_WIDGET = {
  width: 50,
  height: 50,
  x: 0,
  y: 0,
};

const DEFAULT_DATA = {
  [WIDGET_TYPES.LAYER]: DEFAULT_LAYER_WIDGET,
  [WIDGET_TYPES.BUTTON]: DEFAULT_BUTTON_WIDGET,
  [WIDGET_TYPES.TEXT]: DEFAULT_TEXT_WIDGET,
  [WIDGET_TYPES.RICH_TEXT]: DEFAULT_RICH_TEXT_WIDGET,
  [WIDGET_TYPES.CLOCK]: DEFAULT_CLOCK_WIDGET,
  [WIDGET_TYPES.IMAGE]: DEFAULT_IMAGE_WIDGET,
  [WIDGET_TYPES.VIDEO]: DEFAULT_VIDEO_WIDGET,
  [WIDGET_TYPES.AUDIO]: DEFAULT_AUDIO_WIDGET,
  [WIDGET_TYPES.PDF]: DEFAULT_PDF_WIDGET,
  [WIDGET_TYPES.PLAYLIST]: DEFAULT_PLAYLIST_WIDGET,
  [WIDGET_TYPES.APP]: DEFAULT_APP_WIDGET,
};

export function generateWidgetId() {
  return `${Date.now()}${Math.random()}`;
}

export function generateDefaultWidgetData(type, override = {}) {
  return {
    wid: generateWidgetId(),
    zIndex: 0,

    ...DEFAULT_DATA[type],
    ...override,
  };
}

export function generateDefaultLayoutData(name) {
  return {
    name,
    aspectRatio: LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE,
    resolution: LAYOUT_RESOLUTIONS.defaultValues[LAYOUT_ASPECT_RATIOS.SIXTEEN_NINE],
    isHorizontal: false,
    widgets: {},
  };
}
