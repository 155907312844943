<template>
  <div class="header">
    <div class="header-top">
      <div class="title">
        <i
          v-if="!!template && (layoutId || groupId || screenId || tileId)"
          :title="returnIconTitle"
          class="material-icons-outlined align-middle button arrow-back"
          @click="returnToOrigin"
        >
          arrow_back
        </i>

        <template v-if="!!template && template?.settings.name">
          <i class="material-icons-outlined align-middle">
            {{ tileId ? 'now_wallpaper' : 'photo_size_select_large' }}
          </i>
          {{ inOverrideMode ? "Edit template's content" : template.settings.name }}
        </template>
      </div>

      <div class="header-buttons">
        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: gridIsOn }"
          @click="toggleGrid"
          title="Grid"
        >
          grid_on
        </i>

        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: snapIsOn }"
          @click="toggleSnap"
          title="Snapping Objects"
        >
          align_horizontal_right
        </i>

        <i
          class="material-icons-outlined align-middle button"
          :class="{ active: !selectedWidget }"
          title="Template Settings"
          @click="openTemplateSettings"
        >
          tune
        </i>

        <button
          type="button"
          class="button-2-primary"
          :class="{ disabled: !templateNeedSaving }"
          @click="saveChanges"
          :disabled="isSavingTemplate || !templateNeedSaving"
        >
          {{ isSavingTemplate ? 'Saving...' : 'Save Changes' }}
        </button>

        <div class="dropdown">
          <i
            class="material-icons-outlined align-middle more-buttons dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            more_vert
          </i>
          <div class="dropdown-menu dropdown-menu-right">
            <button
              v-if="deletableTemplate"
              type="button"
              class="dropdown-item"
              @click="$emit('openDeleteModal')"
            >
              <i class="material-icons-outlined align-middle">delete</i>
              Delete Template
            </button>

            <button
              v-if="!!tileId"
              type="button"
              class="dropdown-item"
              @click="showTemplateCopyModal = true"
            >
              <i class="material-icons align-middle">save</i>
              Save Copy as Template
            </button>
          </div>
        </div>
      </div>
    </div>

    <SaveTileAsTemplateModal
      v-if="showTemplateCopyModal"
      :tile="this.template"
      :overrideIds="overrideIds"
      :groupId="groupId"
      :screenId="screenId"
      @closeModal="showTemplateCopyModal = false"
    />
  </div>
</template>

<script>
  import { WIDGET_TYPES } from '@/models/layoutDesigner';
  import SaveTileAsTemplateModal from '@/components/templates/designer/SaveTileAsTemplateModal.vue';
  import {
    REPLACE_TEMPLATE_DUMMMY_CHILD,
    SET_SAVE_TEMPLATE,
    SET_SAVING_TEMPLATE,
    TEMPLATES_DELETE_REPLACED_WIDGET,
    TEMPLATES_REMOVE_DELETED_CHILDREN,
    TEMPLATE_DESIGNER_DESELECT_WIDGET,
    TEMPLATE_DESIGNER_TOGGLE_GRID,
    TEMPLATE_DESIGNER_TOGGLE_SNAP,
    TEMPLATE_REMOVE_MODIFIED_WIDGET,
    UPDATE_TEMPLATE_WIDGET_THUMBNAIL,
    UPDATE_TILE_WIDGET,
  } from '@/store/actions/templateDesigner';
  import {
    apiAttachTemplateChild,
    apiRemoveChild,
    apiReplaceChildItem,
    apiUpdateTemplate,
    apiUpdateTemplateChild,
  } from '@/api/templates';
  import {
    apiCreateAppWidget,
    apiCreateLayerWidget,
    apiUpdateAppWidget,
    apiUpdateLayerWidget,
  } from '@/api/layouts';
  import {
    apiCreateGroupTileItemOverride,
    apiCreateScreenTileItemOverride,
    apiGroupUpdateTileItemOverride,
    apiRemoveTileChild,
    apiReplaceTileChildItem,
    apiScreenUpdateTileItemOverride,
    apiUpdateTile,
    apiUpdateTileChild,
    apiAttachTileChild,
    apiScreenDeleteTileItemOverride,
    apiGroupDeleteTileItemOverride,
    apiChangeOverrideToDelete,
  } from '@/api/tiles';
  import {
    SCREEN_CONTENT_CHANGED,
    SCREEN_GROUP_CONTENT_CHANGED,
  } from '@/store/actions/screenContentManagment';

  export default {
    name: 'TemplateDesignerHeader',

    components: {
      SaveTileAsTemplateModal,
    },

    data() {
      return {
        showTemplateCopyModal: false,
      };
    },

    computed: {
      template() {
        return this.$store.state.templateDesigner.template;
      },

      templateId() {
        return this.template.template_id;
      },

      widgets() {
        return this.$store.state.templateDesigner.widgets;
      },

      gridIsOn() {
        return this.$store.state.templateDesigner.showTemplateGrid;
      },

      snapIsOn() {
        return this.$store.state.templateDesigner.isSnapGridActive;
      },

      selectedWidget() {
        return this.$store.state.templateDesigner.selectedWidget;
      },

      templateNeedSaving() {
        return this.$store.state.templateDesigner.saveTemplate;
      },

      widgetsToSave() {
        return this.$store.state.templateDesigner.modifiedWidgets;
      },

      widgetsToReplace() {
        return this.$store.state.templateDesigner.replacedWidgets;
      },

      groupId() {
        return this.$store.state.templateDesigner.groupId;
      },

      layoutId() {
        return this.$store.state.templateDesigner.layoutId;
      },

      layoutIdQuery() {
        return this.$route.query.layout;
      },

      playlistCategoryId() {
        return this.$route.query.category;
      },

      rootCategory() {
        return this.$route.query.rootCategory;
      },

      screenId() {
        return this.$store.state.templateDesigner.screenId;
      },

      tileId() {
        return this.$route.query.tile;
      },

      playlistId() {
        return this.$route.query.playlist;
      },

      overrideIds() {
        return Object.values(this.widgets)
          .filter((widget) => !!widget.object.override)
          .map((widget) => widget.object.override.id);
      },

      isTile() {
        return !!this.playlistId;
      },

      inOverrideMode() {
        return !!this.screenId || !!this.groupId;
      },

      deletableTemplate() {
        return !this.screenId && !this.layoutId && !this.groupId && !this.playlistId;
      },

      returnIconTitle() {
        let iconTitle = "Return to Screen's Page";

        if (this.groupId) {
          iconTitle = "Return to Group's Page";
        } else if (this.layoutId || this.playlistId) {
          iconTitle = 'Return to Layout Designer';
        }

        return iconTitle;
      },

      isSavingTemplate: {
        get() {
          return this.$store.state.templateDesigner.isSavingTemplate;
        },
        set(value) {
          this.$store.commit(SET_SAVING_TEMPLATE, value);
        },
      },
    },

    methods: {
      navigatedFromScreenView() {
        return this.screenId && this.groupId === null ? true : false;
      },

      navigatedFromScreenGroupView() {
        return this.screenId && this.groupId !== null ? true : false;
      },

      toggleGrid() {
        this.$store.commit(TEMPLATE_DESIGNER_TOGGLE_GRID, !this.gridIsOn);
      },

      toggleSnap() {
        this.$store.commit(TEMPLATE_DESIGNER_TOGGLE_SNAP, !this.snapIsOn);
      },

      openTemplateSettings() {
        this.$store.commit(TEMPLATE_DESIGNER_DESELECT_WIDGET, !this.snapIsOn);
      },

      async saveChanges() {
        this.isSavingTemplate = true;

        let saved = null;

        if (!this.inOverrideMode) {
          saved = await this.saveTemplate();
        }

        const shouldUpdateWidgets = Object.values(this.widgetsToSave).length;

        if (saved && shouldUpdateWidgets && !this.inOverrideMode) {
          saved = await this.saveTemplateChildren();
        } else if (shouldUpdateWidgets && this.inOverrideMode) {
          saved = await this.saveTileChildrenOverride();
        }

        if (saved) {
          this.$store.commit(SET_SAVE_TEMPLATE, false);

          this.$toasted.global.general_success({
            message: 'Template saved successfully',
          });
        }

        if (this.navigatedFromScreenView()) {
          await this.$store.dispatch(SCREEN_CONTENT_CHANGED, parseInt(this.screenId));
        } else if (this.navigatedFromScreenGroupView()) {
          await this.$store.dispatch(SCREEN_GROUP_CONTENT_CHANGED, {
            screenGroupId: parseInt(this.groupId),
            layoutId: this.layoutId,
            screenIds: null,
          });
        }

        this.isSavingTemplate = false;
      },

      async saveTemplate() {
        try {
          const templateImage = await this.$store.dispatch(UPDATE_TEMPLATE_WIDGET_THUMBNAIL);

          const data = {
            ...this.template.settings,
            image: templateImage,
          };

          return this.playlistId
            ? await apiUpdateTile(this.tileId, data)
            : await apiUpdateTemplate(this.templateId, data);
        } catch (error) {
          console.log('saveTemplate error:', error);

          this.$toasted.global.general_error({
            message: 'Unable to save the Template',
          });
        }
      },

      async saveTemplateChildren() {
        try {
          const createWidgetsPromises = [];
          const updateWidgetsPromises = [];
          const deteleWidgetsPromises = [];

          // CREATE/UPDATE WIDGETS
          const widgetsArray = Object.values(this.widgets);

          widgetsArray.forEach((widget) => {
            const widgetId = widget.assoc_id;

            if (!widgetId) {
              createWidgetsPromises.push(this.createWidget(widget));
              return;
            }

            const updateWidget = this.widgetsToSave[widgetId];

            if (updateWidget) {
              updateWidgetsPromises.push(this.updateWidget(widget));
            }
          });

          // DELETE WIDGETS
          const deletedWidgetsArray = Object.values(
            this.$store.state.templateDesigner.deletedWidgets,
          );

          deletedWidgetsArray.forEach((widget) => {
            deteleWidgetsPromises.push(this.deleteWidget(widget));
          });

          await Promise.all([...updateWidgetsPromises, ...createWidgetsPromises]);

          return true;
        } catch (error) {
          console.log('saveTemplateChildren ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Unable to save one or more widget(s)',
          });

          return false;
        }
      },

      async saveTileChildrenOverride() {
        try {
          const createAddOverridesPromises = [];
          const createUpdateOverridePromises = [];
          const updateOverridePromises = [];
          const deteleWidgetsPromises = [];

          const widgetsArray = Object.values(this.widgets);

          // CREATE/UPDATE WIDGETS
          widgetsArray.forEach((widget) => {
            const widgetId = widget.assoc_id;

            if (!widgetId && this.widgetsToSave[widget.object.wid]) {
              createAddOverridesPromises.push(this.createAddOverride(widget));
              this.$store.commit(UPDATE_TILE_WIDGET, { widget });

              return;
            }

            const updateWidget = this.widgetsToSave[widgetId];

            if (!updateWidget || updateWidget === 'deleted') return;

            const widgetOverride = widget.object.override;

            if (!widgetOverride) {
              createUpdateOverridePromises.push(
                this.createUpdateOverride(widget.object.item_id, widget),
              );
              return;
            }

            updateOverridePromises.push(this.updateOverride(widget.object.item_id, widget));
          });

          // DELETE WIDGETS
          const deletedWidgetsArray = Object.values(
            this.$store.state.templateDesigner.deletedWidgets,
          );

          deletedWidgetsArray.forEach((widget) => {
            deteleWidgetsPromises.push(this.deleteWidgetInOverrideMode(widget));

            this.$store.commit(TEMPLATES_REMOVE_DELETED_CHILDREN, widget.assoc_id);
          });

          await Promise.all([
            ...createAddOverridesPromises,
            ...updateOverridePromises,
            ...createUpdateOverridePromises,
            ...deteleWidgetsPromises,
          ]);

          return true;
        } catch (error) {
          console.log('saveTemplateChildren ~ error:', error);
          this.$toasted.global.general_error({
            message: 'Unable to save one or more widget(s)',
          });

          return false;
        }
      },

      async createAddOverride(widget) {
        try {
          const { object, position } = widget;

          let newItemData;

          switch (widget.itemType) {
            case WIDGET_TYPES.BUTTON:
            case WIDGET_TYPES.TEXT:
            case WIDGET_TYPES.RICH_TEXT:
            case WIDGET_TYPES.CLOCK:
              newItemData = await this.createCommonWidget(widget, false);
              break;
            case WIDGET_TYPES.PDF:
            case WIDGET_TYPES.IMAGE:
            case WIDGET_TYPES.VIDEO:
            case WIDGET_TYPES.AUDIO:
            case WIDGET_TYPES.APP:
              newItemData = {
                object,
                item_id: object.item_id,
                assoc_id: object.wid || `${Date.now()}${Math.random()}`,
              };

              break;
            case WIDGET_TYPES.LAYER:
              newItemData = await this.createLayerWidget(widget, false);
              break;
          }

          const data = {
            action: 'add',
            itemId: newItemData.item_id,
            newItemId: newItemData.item_id,
            overrideConfig: {
              position,
              object: newItemData.object,
            },
            tileId: this.template.template_id,
            playlistId: this.playlistId,
          };

          const response = !!this.groupId
            ? await apiCreateGroupTileItemOverride({
                ...data,
                groupId: this.groupId,
                layout: this.layoutId,
              })
            : await apiCreateScreenTileItemOverride({
                ...data,
                screenId: this.screenId,
              });

          const override = {
            ...response.data,
            config: response.data.config.object,
          };

          widget.object = newItemData.object;
          widget.object.override = override;
          widget.assoc_id = newItemData.assoc_id;

          this.$store.commit(UPDATE_TILE_WIDGET, { widget });
        } catch (error) {
          console.log('createUpdateOverride error', error);
          throw error;
        }
      },

      async createUpdateOverride(newItemId, widget) {
        try {
          const replacedWidgetId = this.widgetsToReplace[widget.assoc_id];
          const { object, position } = widget;

          const data = {
            action: 'edit',
            itemId: replacedWidgetId || object.item_id,
            newItemId,
            overrideConfig: {
              object,
              position,
            },
            tileId: this.template.template_id,
            playlistId: this.playlistId,
          };

          const response = !!this.groupId
            ? await apiCreateGroupTileItemOverride({
                ...data,
                groupId: this.groupId,
                layout: this.layoutId,
              })
            : await apiCreateScreenTileItemOverride({
                ...data,
                screenId: this.screenId,
              });

          const override = {
            ...response.data,
            config: response.data.config.object,
          };

          widget.object.override = override;

          this.$store.commit(UPDATE_TILE_WIDGET, { widget });
        } catch (error) {
          console.log('createUpdateOverride error', error);
          throw error;
        }
      },

      async updateOverride(newItemId, widget) {
        try {
          const replacedWidgetId = this.widgetsToReplace[widget.assoc_id];

          const { object, position } = widget;

          const data = {
            action: widget.object.override.action,
            overrideId: object.override.id,
            itemId: replacedWidgetId || object.item_id,
            newItemId,
            overrideConfig: {
              object: { ...object, override: undefined },
              position,
            },
            tileId: this.template.template_id,
            playlistId: this.playlistId,
          };

          !!this.groupId
            ? await apiGroupUpdateTileItemOverride(data)
            : await apiScreenUpdateTileItemOverride(data);

          this.$store.commit(TEMPLATE_REMOVE_MODIFIED_WIDGET, widget.assoc_id);
        } catch (error) {
          console.log('updateOverride error', error);
          throw error;
        }
      },

      async createWidget(widget) {
        switch (widget.itemType) {
          case WIDGET_TYPES.BUTTON:
          case WIDGET_TYPES.TEXT:
          case WIDGET_TYPES.RICH_TEXT:
          case WIDGET_TYPES.CLOCK:
            return this.createCommonWidget(widget);

          case WIDGET_TYPES.PDF:
          case WIDGET_TYPES.IMAGE:
          case WIDGET_TYPES.VIDEO:
          case WIDGET_TYPES.AUDIO:
          case WIDGET_TYPES.APP:
            const widgetData = {
              type: widget.type,
              position: widget.position,
              item_id: widget.object.item_id,
            };

            if (widget.itemType === WIDGET_TYPES.APP && !this.isTile) {
              await apiUpdateAppWidget(widget.object.item_id, widget.object);
            } else if (this.isTile) {
              widgetData.config = widget.object;
            }

            return this.attachWidgetToTemplate({
              widgetData,
              widgetType: widget.type,
              widget: widget.object,
            });
          case WIDGET_TYPES.LAYER:
            return this.createLayerWidget(widget);
        }
      },

      async createLayerWidget(widget, attachToTemplate = true) {
        const createResponse = await apiCreateLayerWidget(widget.object);

        const widgetData = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          item_id: createResponse.data.item_id,
          position: widget.position,
        };

        if (!attachToTemplate) {
          return {
            object: createResponse.data,
            itemType: WIDGET_TYPES.LAYER,
            type: WIDGET_TYPES.LAYER,
            item_id: createResponse.data.item_id,
            assoc_id: createResponse.data.wid,
          };
        }

        await this.attachWidgetToTemplate({
          widgetData,
          widgetType: WIDGET_TYPES.LAYER,
          widget: { ...createResponse.data, wid: widget.object.wid },
        });
      },

      async createCommonWidget(widget, attachToTemplate = true) {
        const response = await apiCreateAppWidget(widget.widgetData);

        if (!attachToTemplate) {
          return {
            object: {
              ...response.data,
              ...response.data.config,
              type: widget.itemType,
              config: undefined,
            },
            itemType: widget.itemType,
            type: widget.type,
            item_id: response.data.item_id,
            assoc_id: widget.object.wid || `${Date.now()}${Math.random()}`,
          };
        }

        const widgetData = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          item_id: response.data.item_id,
          position: widget.position,
        };

        const newWidget = {
          ...widget.object,
          ...response.data,
          ...response.data.config,
          type: widget.itemType,
          config: undefined,
        };

        return await this.attachWidgetToTemplate({
          widgetData,
          itemType: widget.itemType,
          widget: newWidget,
        });
      },

      async attachWidgetToTemplate({
        widgetData,
        itemType,
        widgetType = WIDGET_TYPES.LAYOUT_ITEM,
        widget,
      }) {
        const updateResponse = this.isTile
          ? await apiAttachTileChild(this.tileId, widgetData)
          : await apiAttachTemplateChild({
              templateId: this.templateId,
              widgetData,
            });

        this.$store.commit(REPLACE_TEMPLATE_DUMMMY_CHILD, {
          widget: {
            ...updateResponse.data,
            object: widget,
            itemType: itemType || widget.item_type || widgetType,
            type: widgetType,
            assoc_id: updateResponse.data.id,
          },
        });
      },

      async updateWidget(widget) {
        const widgetData = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position: widget.position,
          assoc_id: widget.assoc_id,
        };

        switch (widget.itemType) {
          case WIDGET_TYPES.BUTTON:
          case WIDGET_TYPES.CLOCK:
          case WIDGET_TYPES.RICH_TEXT:
          case WIDGET_TYPES.TEXT:
            if (this.isTile) {
              widgetData.config = widget.object;
              break;
            }

            await apiUpdateAppWidget(widget.object.item_id, {
              config: widget.object,
            });

            break;
          case WIDGET_TYPES.IMAGE:
          case WIDGET_TYPES.PDF:
          case WIDGET_TYPES.VIDEO:
          case WIDGET_TYPES.AUDIO:
          case WIDGET_TYPES.APP:
            const widgetReplace = this.widgetsToReplace[widget.assoc_id];

            if (widget.itemType === WIDGET_TYPES.APP && !this.isTile) {
              await apiUpdateAppWidget(widget.object.item_id, widget.object);
            } else if (this.isTile) {
              widgetData.config = widget.object;
            }

            if (!widgetReplace) break;

            const replaceData = {
              new_item_id: widgetReplace,
              type: widget.type,
              assoc_id: widget.assoc_id,
            };

            const response = this.isTile
              ? await apiReplaceTileChildItem(this.tileId, replaceData)
              : await apiReplaceChildItem(this.templateId, replaceData);

            this.$store.commit(TEMPLATES_DELETE_REPLACED_WIDGET, widget.assoc_id);

            break;
          case WIDGET_TYPES.LAYER:
            await apiUpdateLayerWidget(widget.object.item_id, widget.object);
            break;
        }

        this.isTile
          ? await apiUpdateTileChild(this.tileId, widgetData)
          : await apiUpdateTemplateChild(this.templateId, widgetData);

        this.$store.commit(TEMPLATE_REMOVE_MODIFIED_WIDGET, widget.assoc_id);
      },

      async deleteWidget(widget) {
        const payload = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          assoc_id: widget.assoc_id,
        };

        this.isTile
          ? await apiRemoveTileChild(this.tileId, payload)
          : await apiRemoveChild(this.templateId, payload);

        this.$store.commit(TEMPLATES_REMOVE_DELETED_CHILDREN, widget.assoc_id);
      },

      async deleteWidgetInOverrideMode(widget) {
        //No Overrides
        if (!widget.object.override) {
          const payload = {
            action: 'remove',
            itemId: widget.object.item_id,
            newItemId: widget.object.item_id,
            overrideConfig: {},
            tileId: this.template.template_id,
            playlistId: this.playlistId,
            groupId: this.groupId,
            screenId: !!this.groupId ? null : this.screenId,
            layout: this.layoutId,
          };

          !!this.groupId
            ? await apiCreateGroupTileItemOverride(payload)
            : await apiCreateScreenTileItemOverride(payload);

          return;
        }

        //Add Actions
        if (widget.object.override.action === 'add') {
          !!this.groupId
            ? await apiGroupDeleteTileItemOverride(
                this.groupId,
                this.template.template_id,
                widget.object.override.id,
              )
            : await apiScreenDeleteTileItemOverride(
                this.screenId,
                this.template.template_id,
                widget.object.override.id,
              );

          return;
        }

        //Edit Actions
        await apiChangeOverrideToDelete(widget.object.override.id);
      },

      returnToOrigin() {
        if (this.groupId) {
          this.$router.push({
            name: 'ScreenLayout',
            params: { groupId: this.groupId, layoutId: this.layoutIdQuery },
            query: { playlistId: this.playlistId },
          });
          return;
        }

        if (this.screenId) {
          this.$router.push({
            name: 'Screen',
            params: { screenId: this.screenId },
            query: { playlistId: this.playlistId },
          });
          return;
        }

        if (this.layoutId || this.playlistId) {
          this.$router.push({ name: 'Layout', params: { layout_id: this.layoutId } });
          return;
        }
      },
    },
  };
</script>

<style lang="scss">
  .header {
    margin-bottom: 0;

    .header-top {
      flex-wrap: wrap;
      font-family: 'Poppins';
      gap: 16px;

      .title {
        display: flex;
        gap: 8px;
        align-items: center;
        color: $primaryText;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      .button-2-primary {
        padding: 8px 12px;
        height: 36px;
      }

      button.disabled {
        opacity: 0.5;
      }
    }

    .header-buttons {
      display: flex;
      align-items: center;
      gap: 16px;

      .button {
        cursor: pointer;

        i {
          &:hover {
            color: $primaryRed;
          }
        }

        &:hover {
          color: $primaryRed;
        }

        &.active {
          i {
            color: $primaryRed;
          }
        }
      }
    }

    .arrow-back {
      margin-right: 8px;

      &:hover {
        color: $primaryText;
      }
    }
  }
</style>
