<template>
  <div class="widget-toolbar align-content-center">
    <p>Add Item to Template:</p>

    <Button
      v-if="hideToolbarItems && inOverrideMode"
      @click="hideToolbarItems = false"
      theme="grey"
      size="full"
    >
      + Widget
    </Button>

    <div v-else class="widget-buttons">
      <div class="widget-button" title="Layer" @click.prevent="createLayer">
        <i class="material-icons-outlined align-middle">layers</i>
        <span>Layer</span>
      </div>

      <div class="widget-button" title="Button" @click.prevent="createButton">
        <i class="material-icons-outlined align-middle">smart_button</i>
        <span>Button</span>
      </div>

      <div
        class="widget-button"
        title="Image"
        @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.IMAGE)"
      >
        <i class="material-icons-outlined align-middle">image</i>
        <span>Image</span>
      </div>

      <div
        class="widget-button"
        title="Video"
        @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.VIDEO)"
      >
        <i class="material-icons-outlined align-middle">play_circle_outline</i>
        <span>Video</span>
      </div>

      <div
        class="widget-button"
        title="Audio"
        @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.AUDIO)"
      >
        <i class="material-icons-outlined align-middle">audio_file</i>
        <span>Audio</span>
      </div>

      <div class="widget-button" title="Text" @click.prevent="createText">
        <i class="material-icons-outlined align-middle">text_fields</i>
        <span>Text</span>
      </div>

      <div class="widget-button" title="Text" @click.prevent="createRichText">
        <i class="material-icons-outlined align-middle">edit_note</i>
        <span>Rich Text</span>
      </div>

      <div
        class="widget-button"
        title="PDF"
        @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.PDF)"
      >
        <i class="material-icons-outlined align-middle">picture_as_pdf</i>
        <span>PDF</span>
      </div>

      <div
        class="widget-button"
        title="APP"
        @click.prevent="openContentBrowser(WIDGET_CONTENT_TYPE.APP)"
      >
        <i class="material-icons-outlined align-middle">apps</i>
        <span>APP</span>
      </div>

      <div class="widget-button" title="Clock" @click.prevent="createClock">
        <i class="material-icons-outlined align-middle">access_time</i>
        <span>Clock</span>
      </div>
    </div>

    <ContentBrowserModal
      ref="content_browser_modal"
      @submitted="[createContentWidget($event), closeContentBrowser()]"
      :allow-multiple-item-select="false"
      :filterItemTypes="filterItemTypes[selectedItemType]"
    >
    </ContentBrowserModal>
  </div>
</template>

<script>
  import config from '@/config';

  import { TEMPLATE_DESIGNER_ADD_WIDGET } from '@/store/actions/templateDesigner';

  import {
    generateDefaultWidgetData,
    IMAGE_MODE,
    WIDGET_CONTENT_TYPE,
    WIDGET_TYPES,
  } from '@/models/layoutDesigner';

  import ContentBrowserModal from '@/components/content/ContentBrowserModal.vue';
  import Button from '@/components/common/Button.vue';

  import { getEncodedURL } from '@/helpers/mixins';
  import { CONTENT_TYPE_WEATHER_APP } from '@/constant/const';
  import { weatherDefaultOptions } from '@/constant/weatherApp';

  export default {
    name: 'WidgetToolbar',

    components: {
      ContentBrowserModal,
      Button,
    },

    data() {
      return {
        filterItemTypes: {
          [WIDGET_CONTENT_TYPE.IMAGE]: [WIDGET_CONTENT_TYPE.IMAGE],
          [WIDGET_CONTENT_TYPE.APP]: [WIDGET_CONTENT_TYPE.APP],
          [WIDGET_CONTENT_TYPE.VIDEO]: [WIDGET_CONTENT_TYPE.VIDEO],
          [WIDGET_CONTENT_TYPE.AUDIO]: [WIDGET_CONTENT_TYPE.AUDIO],
          [WIDGET_CONTENT_TYPE.PDF]: [WIDGET_CONTENT_TYPE.PDF],
        },
        selectedItemType: 'image',
        WIDGET_CONTENT_TYPE,
        hideToolbarItems: true,
      };
    },

    computed: {
      template() {
        return this.$store.state.templateDesigner.template;
      },

      nextZIndex() {
        return Object.keys(this.$store.state.templateDesigner.widgets).length;
      },

      inOverrideMode() {
        const { groupId, screenId } = this.$store.state.templateDesigner;

        return !!screenId || !!groupId;
      },
    },

    methods: {
      prepareAppData(widgetType, override = {}) {
        const defaultWidget = generateDefaultWidgetData(widgetType, {
          zIndex: this.nextZIndex,
          width: 50,
          height: 50,
          ...override,
        });

        return {
          templateId: this.template.template_id,
          widget: defaultWidget,
          position: this.getPositionData(defaultWidget),
          widgetData: {
            type: widgetType,
            name: defaultWidget.name,
            config: defaultWidget,
          },
        };
      },

      createLayer() {
        const defaultLayerWidget = generateDefaultWidgetData(WIDGET_TYPES.LAYER, {
          width: 50,
          height: 50,
        });

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: defaultLayerWidget,
          position: this.getPositionData(defaultLayerWidget),
          itemType: WIDGET_TYPES.LAYER,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      createButton() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.BUTTON, {
          height: 18,
          width: 30,
        });

        widgetData.buttonText = widget.text;

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.BUTTON,
          widgetData,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      createText() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.TEXT);

        widgetData.userText = widget.userText;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.TEXT,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      createRichText() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.RICH_TEXT);

        widgetData.userText = widget.userText;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.RICH_TEXT,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      createClock() {
        const { widget, position, widgetData } = this.prepareAppData(WIDGET_TYPES.CLOCK);

        widgetData.clockName = widget.name;

        const newWidget = {
          widgetData,
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: widget,
          position,
          itemType: WIDGET_TYPES.CLOCK,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createImage(item) {
        const imageWidget = generateDefaultWidgetData(WIDGET_TYPES.IMAGE, {
          uuid: item.item_id,
          item_url: item.item_url,
          item_id: item.item_id,
          width: 50,
          height: 50,
        });

        const widgetPositions = this.getPositionData(imageWidget);

        const position = {
          ...imageWidget,
          ...item,
          ...widgetPositions,
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          object: imageWidget,
          position,
          itemType: WIDGET_TYPES.IMAGE,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createApp(item) {
        const appInfo = this.prepareAppData(WIDGET_TYPES.APP);
        const itemObject = {
          ...item,
          config: undefined,
          ...appInfo.widget,
          ...item.config,
          type: WIDGET_TYPES.APP,
          item_type: item.item_type,
          item_id: item.item_id,
        };

        if (item.item_type === CONTENT_TYPE_WEATHER_APP) {
          itemObject.weather = item.weather
            ? item.weather
            : {
                ...weatherDefaultOptions,
                name: item.name,
                backgroundColor: item.background?.color,
                language: item.lang,
                temperatureUnit: item.temp_unit,
                timeFormat: item.time_format,
                location: {
                  label: item.name,
                  name: item.name,
                  coordinates: item.location,
                },
              };
        }

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position: appInfo.position,
          object: itemObject,
          itemType: WIDGET_TYPES.APP,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createVideo(item) {
        const videoWidget = generateDefaultWidgetData(WIDGET_TYPES.VIDEO, {
          uuid: item.item_id,
          item_id: item.item_id,
          width: 50,
          height: 50,
        });

        const widgetPositions = this.getPositionData(videoWidget);

        const position = {
          ...widgetPositions,
          data: {
            ...videoWidget,
            ...item,
            thumbnail_url: getEncodedURL(config.baseUrl, item.thumbnail_url),
            item_url: getEncodedURL(config.baseUrl, item.item_url),
          },
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: videoWidget,
          itemType: WIDGET_TYPES.VIDEO,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createAudio(item) {
        const audioWidget = generateDefaultWidgetData(WIDGET_TYPES.AUDIO, {
          uuid: item.item_id,
          item_id: item.item_id,
        });

        const widgetPositions = this.getPositionData(audioWidget);

        const position = {
          ...widgetPositions,
          data: {
            ...audioWidget,
            ...item,
            item_url: getEncodedURL(config.baseUrl, item.item_url),
          },
        };

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: audioWidget,
          itemType: WIDGET_TYPES.AUDIO,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createPdf(item) {
        if (!item.item_id) {
          this.$toasted.global.general_error({ message: 'Something is wrong with your PDF!' });

          return;
        }

        const pdfWidget = generateDefaultWidgetData(WIDGET_TYPES.PDF, {
          uuid: item.item_id,
          item_id: item.item_id,
          width: 50,
          height: 50,
        });

        const widgetPositions = this.getPositionData(pdfWidget);

        let position = {
          ...pdfWidget,
          ...widgetPositions,
          data: { ...item, pageDuration: 15, imageMode: IMAGE_MODE.CONTAIN },
        };

        if (item.pdf_content) {
          position = { ...position, data: { ...position.data, ...item.pdf_content } };
        }

        const newWidget = {
          type: WIDGET_TYPES.LAYOUT_ITEM,
          position,
          object: pdfWidget,
          itemType: WIDGET_TYPES.PDF,
        };

        this.$store.commit(TEMPLATE_DESIGNER_ADD_WIDGET, newWidget);
      },

      async createContentWidget(selectedItems) {
        if (selectedItems.length === 0) return;

        const item = selectedItems[0];

        switch (this.selectedItemType) {
          case WIDGET_CONTENT_TYPE.IMAGE:
            await this.createImage(item);
            break;
          case WIDGET_CONTENT_TYPE.APP:
            await this.createApp(item);
            break;
          case WIDGET_CONTENT_TYPE.VIDEO:
            await this.createVideo(item);
            break;
          case WIDGET_CONTENT_TYPE.AUDIO:
            await this.createAudio(item);
            break;
          case WIDGET_CONTENT_TYPE.PDF:
            await this.createPdf(item);
            break;
        }
      },

      openContentBrowser(type = 'image') {
        this.selectedItemType = type;
        this.$refs.content_browser_modal.open();
      },

      closeContentBrowser() {
        this.$refs.content_browser_modal.close();
      },

      getPositionData(widget) {
        const { width = 50, height = 50 } = widget;

        const commonPosition = {
          zIndex: this.nextZIndex,
          width,
          height,
          x: 0,
          y: 0,
          usePixels: true,
          xPixels: 0,
          yPixels: 0,
        };

        const xScale = width / 100;
        const yScale = height / 100;
        const widgetWidth = xScale * this.template.settings.width;
        const widgetHeight = yScale * this.template.settings.height;

        return {
          ...commonPosition,
          widthPixels: widgetWidth,
          heightPixels: widgetHeight,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-toolbar {
    a.btn {
      width: 44px;
      height: 42px;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: #d0d0d0;
      }
    }
  }

  .widget-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 14px 8px;

    .widget-button {
      display: flex;
      align-items: center;
      gap: 6px;

      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding: 4px 12px;
      background-color: $highlightGrey;
      color: $tableText;
      border-radius: 20px;

      &:hover {
        cursor: pointer;
        background-color: $lineGrey2;
      }
    }
  }
</style>
