<template>
  <SidebarContainer class="screen-groups-sidebar-container">
    <template #default v-if="isLoading">
      <Loader />
    </template>
    <template #default v-else-if="!currentLayout">
      <div class="groups-sidebar">
        <OrientationTabs
          :activeTab="activeOrientation"
          :horizontal-layouts-count="layouts.filter((layout) => isLayoutHorizontal(layout)).length"
          :vertical-layouts-count="layouts.filter((layout) => !isLayoutHorizontal(layout)).length"
          @update:activeTab="(type) => (activeOrientation = type)"
        />

        <LayoutsList
          @select="selectLayout"
          :layouts="filteredLayouts"
          :empty-text="`This Screen Group has 0 ${activeOrientation} layouts`"
        />

        <div class="preview-container">
          <div class="preview-footer">
            <div class="online-status"></div>
            <div class="right">
              <RotateScreenButton
                title="Rotate Screens"
                :isRotating="isRotating"
                @rotateScreens="(orientation = 'portrait') => rotateScreens(null, orientation)"
              />

              <div v-if="isRefreshing">
                <Loader :size="20" />
              </div>
              <i
                v-else
                class="material-icons align-middle button"
                title="Restart Screens"
                @click="refreshScreens()"
              >
                refresh
              </i>
            </div>
          </div>
        </div>

        <div class="sidebar-container">
          <BaseInput label="Group Name" v-if="screenGroup" v-model="groupName" />

          <div class="quick-actions">
            <BaseButton theme="secondary" icon="swap_vertical_circle" @click="openSwapLayoutModal">
              Layout Swap
            </BaseButton>
            <BaseButton
              v-if="isAdmin || isLocalAdmin"
              theme="secondary"
              icon="edit_attributes"
              @click.native="openPermissionsModal"
            >
              Manage Permissions
            </BaseButton>
          </div>

          <SwapLayoutModal ref="swapLayoutModal" />
          <PermissionsManagerModal
            v-if="showPermissionsModal"
            @close="showPermissionsModal = false"
            :itemId="screenGroup.id"
            :type="ROLE_ACCESS_TYPES.SCREEN_GROUPS"
          />
        </div>
      </div>
    </template>

    <template #default v-else>
      <div class="groups-sidebar">
        <OrientationTabs
          :activeTab="activeOrientation"
          :horizontal-layouts-count="layouts.filter((layout) => isLayoutHorizontal(layout)).length"
          :vertical-layouts-count="layouts.filter((layout) => !isLayoutHorizontal(layout)).length"
          @update:activeTab="handleOrientationChange"
        />

        <div class="sidebar-container">
          <div v-if="!!currentLayout" class="return-group" @click="currentLayout = null">
            <i class="material-icons-outlined align-middle button">arrow_back</i>

            <div class="return-text">
              Previewing <span>{{ currentLayout.settings.name }}</span>
            </div>
          </div>

          <div class="preview-container">
            <div class="preview-content" :style="{ aspectRatio: previewAspectRatio }">
              <Player
                v-if="!showPreviewModal"
                :groupId="screenGroup.id"
                :layout_id="layoutId"
                :key="key"
                isPreview
              />
            </div>

            <div class="preview-footer">
              <ConnectionLights
                :connected="connectedScreensWithCurrentLayout.length"
                :unknown="unknownScreensWithCurrentLayout.length"
                :disconnected="disconnectedScreensWithCurrentLayout.length"
                :size="12"
              />
              <div class="right">
                <RotateScreenButton
                  title="Rotate Screens"
                  :isRotating="isRotating"
                  @rotateScreens="
                    (orientation = 'portrait') =>
                      rotateScreens(groupScreensWithCurrentLayout, orientation)
                  "
                />

                <div v-if="isRefreshing">
                  <Loader :size="20" />
                </div>
                <i
                  v-else-if="connectedScreensWithCurrentLayout.length > 0"
                  class="material-icons align-middle button"
                  title="Restart Screen"
                  @click="refreshScreens(groupScreensWithCurrentLayout)"
                >
                  refresh
                </i>
                <i
                  class="material-icons-outlined align-middle button"
                  @click="openPreviewOverlay"
                  title="Open Full Screen"
                >
                  fullscreen
                </i>
              </div>
            </div>
          </div>

          <BaseSelect
            label="Layout Selector"
            small="(Horizontal)"
            icon="dashboard"
            v-model="layoutId"
          >
            <option
              v-for="layout in allLayouts"
              :key="layout.layout_id"
              :value="layout.layout_id"
              :selected="layout.layout_id === currentLayout?.layout_id"
            >
              {{ layout.settings.name }}
            </option>
          </BaseSelect>
        </div>

        <LayoutsPreviewModal
          v-if="showPreviewModal && currentLayout"
          :groupId="screenGroup.id"
          :layoutId="currentLayout?.layout_id"
          @closeModal="handleClosePreview"
        />
      </div>
    </template>

    <template #buttons>
      <BaseButton
        v-if="currentLayout"
        :icon="isGroupOverridable ? 'edit' : 'lock'"
        variant="outline"
        @click.native="handleEditGroupClick"
      >
        Edit Screens
      </BaseButton>

      <BaseButton
        v-if="!isLoading && !currentLayout"
        :loading="isSaving"
        icon="save"
        @click="saveGroupData"
      >
        Save Changes
      </BaseButton>

      <BaseButton
        v-else-if="!isLoading"
        icon="save"
        key="2"
        @click="saveLayoutData"
        :loading="isSaving"
      >
        Save Changes
      </BaseButton>

      <modal
        v-show="showOverrideBlockedModal"
        :modalStyles="{ width: '600px' }"
        noCancelButton
        title="Access Denied"
        :okFunction="() => (showOverrideBlockedModal = false)"
      >
        <template v-slot:body>
          <div class="modal-description">
            <p>
              You are not allowed to override this group's layouts. Because you don't have access to
              all the sub-groups in it.
            </p>
            <p>You can still override individual screens, or sub-groups that you have access to.</p>
          </div>
        </template>
      </modal>
    </template>
  </SidebarContainer>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ConnectionLights from '@/components/common/screens/ConnectionLights.vue';
  import ScreenRotationIcons from '@/components/common/screens/ScreenRotationIcons.vue';
  import SidebarContainer from '@/components/common/SidebarContainer.vue';
  import MatchiPlaylistPlayer from '@/components/MatchiPlaylistPlayer.vue';
  import DeleteGroupModal from '@/components/screens/DeleteGroupModal.vue';
  import OrientationTabs from '@/components/common/OrientationTabs/OrientationTabs.vue';
  import LayoutsList from '@/components/screens/LayoutsList.vue';
  import SwapLayoutModal from '@/components/screens/SwapLayoutModal.vue';
  import Loader from '@/components/common/Loader.vue';
  import Modal from '@/components/common/Modal.vue';
  import Player from '@/components/player/Index.vue';
  import RotateScreenButton from '@/components/common/screens/RotateScreenButton.vue';
  import LayoutsPreviewModal from '@/components/layouts/LayoutsPreviewModal.vue';
  import {
    SCREEN_GROUP_UPDATE,
    SCREEN_LAYOUT_FETCH,
    SCREENS_RESTART,
  } from '@/store/actions/screens';
  import { apiReadLayouts } from '@/api/layouts';
  import {
    apiAddScreenLayout,
    apiGetGroupOverridePermission,
    rotateScreen,
    apiGetScreenGroupLayouts,
  } from '@/api/screens';
  import { PublicationType } from '@/types/api/screens';
  import PermissionsManagerModal from '@/components/common/PermissionsManagerModal.vue';
  import { ROLE_ACCESS_TYPES } from '@/constant/roleAccess';
  import { SET_PREVIEW } from '@/store/actions/preview';

  export default {
    name: 'ScreenGroupSidebar',

    inject: ['getGroupScreens', 'getCurrentScreenGroup', 'reloadScreensData'],

    components: {
      SidebarContainer,
      DeleteGroupModal,
      ScreenRotationIcons,
      MatchiPlaylistPlayer,
      ConnectionLights,
      OrientationTabs,
      LayoutsList,
      SwapLayoutModal,
      Loader,
      Modal,
      Player,
      LayoutsPreviewModal,
      RotateScreenButton,
      PermissionsManagerModal,
    },

    data() {
      return {
        key: 1,
        isLoading: false,
        isSaving: false,
        isRotating: false,
        isRefreshing: false,
        showPreviewModal: false,

        groupName: '',
        activeOrientation: 'vertical',
        currentLayout: null,
        showDeleteGroupModal: false,
        layouts: [],
        allLayouts: [],

        layoutId: null,

        isGroupOverridable: true,
        showOverrideBlockedModal: false,
        showPermissionsModal: false,
        ROLE_ACCESS_TYPES,
      };
    },

    computed: {
      groupScreens() {
        return this.getGroupScreens();
      },

      screenGroup() {
        return this.getCurrentScreenGroup();
      },

      layoutOptions() {
        return this.allLayouts.map((layout) => ({
          value: layout.layout_id,
          label: layout.settings.name,
        }));
      },

      filteredLayouts() {
        return this.layouts.filter((layout) =>
          this.activeOrientation === 'horizontal'
            ? layout?.settings?.isHorizontal
            : !layout?.settings?.isHorizontal,
        );
      },

      groupScreensWithCurrentLayout() {
        return this.groupScreens.filter(
          (screen) => screen.layout === this.currentLayout?.layout_id,
        );
      },

      connectedScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) => screen?.screen_information?.connection_status === true,
        );
      },

      disconnectedScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) => screen?.screen_information?.connection_status === false,
        );
      },

      unknownScreensWithCurrentLayout() {
        return this.groupScreensWithCurrentLayout.filter(
          (screen) =>
            !screen?.screen_information || screen?.screen_information?.connection_status === null,
        );
      },

      previewAspectRatio() {
        if (!this.currentLayout) return;
        const layout = this.currentLayout;
        const originalRatio = layout.settings.aspectRatio;

        if (!layout.settings.isHorizontal) return originalRatio.split(':').reverse().join('/');

        return originalRatio.replace(':', '/');
      },

      screensFilteredByGroupLayout() {
        return this.groupScreens.filter((screen) => screen.layout === this.currentLayout.layout_id);
      },

      ...mapGetters({
        isAdmin: 'isAdministrator',
        isLocalAdmin: 'isOrganizationAdmin',
      }),
    },

    watch: {
      screenGroup(newVal) {
        if (newVal) {
          this.currentLayout = null;
          this.groupName = newVal.name;
          this.fetchData();
        }
      },
    },

    methods: {
      closeDeleteGroupModal() {
        this.showDeleteGroupModal = false;
      },

      async saveGroupData() {
        this.isSaving = true;

        try {
          const dataToSave = {
            name: this.groupName,
          };

          await this.$store.dispatch(SCREEN_GROUP_UPDATE, {
            groupId: this.screenGroup.id,
            screenData: dataToSave,
          });

          this.$toasted.global.general_success({
            message: `Changes successfully saved`,
          });
          await this.reloadScreensData();
        } catch (error) {
          this.$toasted.global.general_error({ message: 'Unable to save group changes' });
        }

        this.isSaving = false;
      },

      async saveLayoutData() {
        this.isSaving = true;
        const screensUsingLayout = this.groupScreens.filter(
          (screen) => screen.layout === this.currentLayout.layout_id,
        );

        try {
          const promises = [];

          screensUsingLayout.forEach((screen) => {
            promises.push(apiAddScreenLayout(screen.id, this.layoutId));
          });

          await Promise.all(promises);

          this.$toasted.global.general_success({
            message: `Changes successfully saved`,
          });

          await this.reloadScreensData();
        } catch (e) {
          this.$toasted.global.general_error({ message: 'Unable to save layout changes' });
          console.log(e);
        }
        this.isSaving = false;
      },

      async fetchData(silent = false) {
        this.isLoading = !silent;
        await this.fetchLayoutData();
        this.fetchGroupPermission();
        this.key += 1;
        this.isLoading = false;
      },

      async fetchLayoutData() {
        this.layouts = await apiGetScreenGroupLayouts(this.screenGroup.id);
      },

      async fetchGroupPermission() {
        try {
          const response = await apiGetGroupOverridePermission(this.screenGroup.id);
          this.isGroupOverridable = response.data.override_permission;
        } catch (error) {
          console.log(error);
          this.isGroupOverridable = true;
        }
      },

      async fetchAllLayouts() {
        const response = await apiReadLayouts();
        this.allLayouts = response.data.items;
      },

      async rotateScreens(screens, orientation) {
        this.isRotating = true;
        const screensList = screens || this.groupScreens;

        try {
          const promises = screensList.map(async (screen) => {
            const layout = this.layouts.find((layout) => layout.layout_id === screen.layout);

            if (!layout) return;

            await rotateScreen(screen.id, orientation);
          });

          await Promise.all(promises);
          await this.fetchData(true);
          await this.reloadScreensData(true);
          this.$toasted.global.general_success({
            message: `Screens rotated successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to rotate screen' });
        }
        this.isRotating = false;
      },

      async refreshScreens(screens) {
        this.isRefreshing = true;
        screens = !!screens ? screens : this.groupScreens;

        try {
          await this.$store.dispatch(SCREENS_RESTART, {
            publicationType: PublicationType.ScreenGroupPublication,
            data: {
              screen_group_id: this.screenGroup.id,
              layout_id: null,
            },
          });

          await this.fetchData(true);

          this.$toasted.global.general_success({
            message: `Refresh requests sent successfully`,
          });
        } catch (error) {
          console.log(error);
          this.$toasted.global.general_error({ message: 'Unable to refresh screens' });
        }
        this.isRefreshing = false;
      },

      isLayoutHorizontal(layout) {
        return layout.settings.isHorizontal;
      },

      handleEditGroupClick() {
        if (!this.isGroupOverridable) {
          this.showOverrideBlockedModal = true;
          return;
        }

        this.$router.push({
          name: 'ScreenLayout',
          params: {
            groupId: this.screenGroup.id,
            layoutId: this.currentLayout.layout_id,
          },
        });
      },

      handleOrientationChange(orientation) {
        this.currentLayout = null;
        this.activeOrientation = orientation;
      },

      selectLayout(layoutId) {
        this.currentLayout = this.layouts.find((layout) => layout.layout_id === layoutId);
        this.layoutId = layoutId;
      },

      openPermissionsModal() {
        if (!this.screenGroup.id) return;
        this.showPermissionsModal = true;
      },
      openSwapLayoutModal() {
        this.$refs.swapLayoutModal.open();
      },

      openPreviewOverlay() {
        this.$store.dispatch(SET_PREVIEW, true);
        this.showPreviewModal = true;
      },

      handleClosePreview() {
        this.$store.dispatch(SET_PREVIEW, false);
        this.showPreviewModal = false;
      },
    },

    created() {
      this.fetchData();
      this.groupName = this.screenGroup.name;
      this.fetchAllLayouts();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .overlay-modal {
    .ui-modal-content {
      aspect-ratio: 16/9;
      width: auto !important;
      padding: 10px !important;
      border-radius: 4px;
      background-color: #fafafa !important;

      .web-player-v2 {
        height: 100%;
        border: 2px solid $primaryRed;
      }
    }
  }
  .screen-groups-sidebar-container {
    padding-top: 50px;
  }
  .groups-sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .quick-actions {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .dropdown-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 8px;

    .dropdown {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      padding: 8px 0;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .return-group {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $secondaryText;
    gap: 16px;
    cursor: pointer;

    span {
      color: $primaryText;
      font-weight: 700;
    }

    &:hover {
      i {
        color: $primaryRed;
      }
    }
  }

  .multiple-sidebar-container,
  .sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .preview-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .preview-content {
      min-height: 205px;
      background-color: $fillGrey;

      ::v-deep .web-player-v2 {
        height: 100%;
      }
    }

    .preview-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 20px;

      .right {
        display: flex;
        gap: 10px;
      }
    }
  }

  .form-group {
    width: 100%;
    margin-bottom: 0px;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      gap: 8px;

      font-weight: 500;
      line-height: 24px;

      .label {
        display: flex;
        gap: 8px;
        align-items: center;

        font-size: 14px;
      }

      input,
      select {
        padding: 8px 16px;

        border: 2px solid $borderGrey;
        border-radius: 8px;
        font-size: 16px;
      }
    }
  }

  .modal-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 0;
  }
</style>
